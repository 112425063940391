import { render } from "./EditModal.vue?vue&type=template&id=47db66ec"
import script from "./EditModal.ts?vue&type=script&lang=ts"
export * from "./EditModal.ts?vue&type=script&lang=ts"

import "./edit-modal.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "47db66ec"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('47db66ec', script)) {
    api.reload('47db66ec', script)
  }
  
  module.hot.accept("./EditModal.vue?vue&type=template&id=47db66ec", () => {
    api.rerender('47db66ec', render)
  })

}

script.__file = "src/views/Main/components/EditModal/EditModal.vue"

export default script